.ant-layout {
    .main-section {
        margin-left: 240px;
        background-color: rgb(239, 241, 245);

        @media screen and (max-width: 991px) {
            margin-left: 0px;
        }
    }

    .ant-layout-sider {
        background: #303136;

        .logo {
            font-weight: bold;
            color: #FFFFFF;
            font-size: 16px;
            display: flex;
            padding: 10px;
            align-items: center;
            border-bottom: 1px solid #666F80;

            .logo-img-wrapper {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0883EF;
                border-radius: 4px;
                margin-right: 12px;
            }
        }
    }
}

.ant-menu-dark {
    background-color: #303136;

    .ant-menu-item {
        border-radius: 0;

        &-selected {
            background-color: #25272C;
            margin-inline: 0;
            border-left: 3px solid #0883EF;
            width: 100%;
        }
    }
}

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.shadow-card {
    border: 1px solid #CED4D9;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 6px 0 rgba(64, 96, 128, 0.1);
}

.flags {
    margin-left: 11px;
    white-space: nowrap;

    button {
        padding: 0;
        background-color: unset;
        border: unset;

        &:hover {
            cursor: pointer;
        }

        img {
            margin: 0 2px;
            width: 20px;
            height: 15px;
            border-radius: 2px;

            &.active {
                -webkit-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                -moz-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
                box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);

            }
        }
    }


}