.messages-layout {

    h1 {
        font-size: 21px;
        line-height: 25px;
        margin: 0 0 24px;
    }

    .filters-row {
        margin-bottom: 10px;

        p {
            font-size: 12px;
            line-height: 15px;
            color: #667380;
            margin: 0 0 6px;
        }
    }

    .message-card {
        background-color: white;
        border-bottom: 1px solid #E4E7EB;
        padding: 26px 0;

        .initials-bubble {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: rgba(8, 131, 239, 0.1);
            color: #0883EF;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;

            &.answered {
                background-color: #F0F2F5;
                color: #667380;
            }
        }

        .name {
            font-size: 14px;
            line-height: 22px;
            color: #303136;
        }

        .info {
            font-size: 14px;
            line-height: 22px;
            color: #667380;

            &.small {
                font-size: 12px;
                line-height: 15px;
            }
        }

        .link {
            &.small {
                font-size: 12px;
                line-height: 15px;
            }
        }

        .question {
            font-size: 14px;
            line-height: 17px;
            color: #303136;
            margin: 15px 0 20px;
        }
    }
}