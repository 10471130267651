.login-layout {
    width: 100vw;
    height: 100vh;
    background-color: #eff1f5;
    justify-content: center;
    align-items: center;

    .login-card {
        position: relative;
        border: 1px solid #CED4D9;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 3px 6px 0 rgba(64, 96, 128, 0.1);
        padding: 40px;
        width: 380px;

        @media screen and (max-width: 575px) {
            width: 100%;
        }
    }
}