.learning-modules-layout {

    .big-category-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        cursor: pointer;

        &:hover {
            box-shadow: 0 3px 6px 0 rgb(64 96 128 / 42%);
        }
    }

    .chapter-title-card {
        padding: 11px;
        cursor: pointer;

        &:hover {
            box-shadow: 0 3px 6px 0 rgb(64 96 128 / 42%);
        }
    }

}