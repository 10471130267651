.header-row {
    padding: 14px 20px;
    box-shadow: 0 1px 0 0 rgba(64, 85, 128, 0.1);
    background-color: #FEFEFE;

    p {
        margin: 0;
        font-weight: bolder;
        color: #303136;

    }

    .counter {
        display: inline-flex;
        padding: 5px 8px;
        background-color: rgba(8, 131, 239, 0.1);
        align-items: center;
        justify-content: center;
        color: #0883EF;
        font-size: 11px;
        margin-left: 10px;
    }
}